/* eslint-disable no-undef */
import { unitsKeys, privateGardenKeys, kitchenKeys } from '~/assets/js/mappings'

export default ({ $axios, query, $cookies, $config, store }, inject) => {
  inject('utilities', {
    navigation: {
      home: {
        text: 'Home',
        href: '/',
        classMobile: 'text-sm',
      },
      cerco: {
        icon: 'search',
        iconheader: 'house-search',
        iconMobile: 'house-search',
        text: 'Cerco',
        textMobile: 'Cerco',
        href: '/venditaimmobili',
        children: [
          {
            text: 'Casa',
            href: '/venditaimmobili',
          },
          {
            text: 'Nuove Costruzioni',
            href: '/nuovecostruzioni',
          },
          {
            text: 'Commerciale',
            href: '/immobilicommerciali',
          },
          {
            text: 'Prestige',
            href: '/immobilidipregio',
          },
          ...($config.uclamActive
            ? [
                {
                  text: 'Proprietà UniCredit',
                  href: '/proprieta-unicredit',
                },
              ]
            : []),
        ],
      },
      vendo: {
        icon: 'sell',
        iconheader: 'sell-new',
        iconMobile: 'sell-white',
        text: 'Vendo',
        textMobile: 'Vendo',
        href: '/vendo',
        children: [
          {
            text: 'Vendi casa con noi',
            href: '/vendo',
          },
          {
            text: 'Valuta casa online',
            href: '/valuta-casa',
          },
          {
            text: 'Come fare per vendere casa',
            href: '/news/come-fare/come-vendere-casa-rapidamente/',
          },
          {
            text: 'Documenti per vendere casa',
            href: '/news/come-fare/documenti-vendita-casa/',
          },
        ],
      },
      serviziDocumenti: {
        icon: 'services-documents',
        iconheader: 'services-documents',
        iconMobile: 'services-documents-white',
        text: 'Servizi e Documenti',
        textMobile: 'Servizi e Documenti',
        href: '/servizi-documenti-casa',
      },
      contatti: {
        icon: 'chat',
        iconheader: 'chat',
        iconMobile: 'contact-white',
        text: 'Contatti',
        href: '/contatto',
      },
      news: {
        text: 'Le novità del mercato immobiliare',
        textMobile: 'Le novità del mercato immobiliare',
        href: '',
        icon: '',
        iconMobile: '',
        children: [
          {
            text: 'Mercato immobiliare',
            href: '/news/tag/mercato-immobiliare/',
          },
          {
            text: 'Vendere casa',
            href: '/news/tag/vendere-casa/',
          },
          {
            text: 'Acquistare casa',
            href: '/news/tag/acquistare-casa/',
          },
          {
            text: 'Ecosostenibilità',
            href: '/news/tag/ecosostenibilita/',
          },
          {
            text: 'Normativa',
            href: '/news/tag/normativa/',
          },
          {
            text: 'Documentazione',
            href: '/news/tag/documentazione/',
          },
        ],
      },
      chisiamo: {
        icon: 'about-us',
        iconheader: 'about-us',
        iconMobile: 'about-us',
        text: 'Chi siamo',
        href: '/chisiamo',
      },
      chisiamoFooter: {
        text: 'Chi siamo',
        textMobile: 'Chi siamo',
        children: [
          {
            text: 'Perché sceglierci',
            href: '/chisiamo',
          },
          {
            text: 'Servizi per te',
            href: '/servizi',
          },
          {
            text: 'Area riservata',
            href: '/scopri-area-riservata',
          },
          {
            text: 'App plus',
            href: '/scopri-app-plus',
          },
          {
            text: 'Acquistare un Immobile',
            href: '/acquistarecasa',
          },
          {
            text: 'Vendere un Immobile',
            href: '/vendo',
          },
        ],
      },
      cartadeiservizi: {
        text: 'Carta dei Servizi',
        href: '/cartadeiservizi',
      },
      privacy: {
        text: 'Privacy',
        href: '/privacy',
      },
      datisocietari: {
        text: 'Dati Societari',
        href: '/datisocietari',
      },
      disclaimer: {
        text: 'Disclaimer',
        href: '/disclaimer',
      },
      informativacookies: {
        text: 'Informativa Cookies',
        href: '/informativacookies',
      },
      faiLeTueScelteCookie: {
        text: 'Le tue scelte sui Cookie',
        href: '/fai-le-tue-scelte-cookie',
        cookieLabel: true,
      },
      accessibilita: {
        text: 'Accessibilità',
        href: '/accessibilita',
      },
      modelloorganizzazione: {
        text: 'Modello di Organizzazione D.Lgs. 231/2001',
        href: '/modelloorganizzazione',
      },
      dashboardSell: {
        text: 'Servizi per chi vende casa',
        href: '/area-riservata/dashboard?servizi=venditore',
        icon: 'house-care-gray',
        iconHover: 'house-care-gray',
        counter: null,
        hover: false,
      },
      dashboardBuy: {
        text: 'Servizi per chi cerca casa',
        href: '/area-riservata/dashboard?servizi=compratore',
        icon: 'house-search',
        iconHover: 'house-search',
        counter: null,
        hover: false,
        children: {
          favorites: {
            text: 'Annunci preferiti',
            href: '/area-riservata/annunci-preferiti',
            icon: 'favorites-gray',
            iconHover: 'favorites-gray',
            counter: null,
            hover: false,
          },
          hidden: {
            text: 'Annunci nascosti',
            href: '/area-riservata/annunci-nascosti',
            icon: 'trash-gray',
            iconHover: 'trash-gray',
            counter: null,
            hover: false,
          },
          researches: {
            text: 'Ricerche salvate',
            href: '/area-riservata/ricerche-salvate',
            icon: 'researches-gray',
            iconHover: 'researches-gray',
            counter: null,
            hover: false,
          },
          notifications: {
            text: 'Notifiche',
            href: '/area-riservata/notifiche',
            icon: 'notifications-gray',
            iconHover: 'notifications-gray',
            counter: null,
            hover: false,
          },
        },
      },
      profile: {
        text: 'Profilo',
        href: '/area-riservata/modifica-profilo',
        icon: 'profile-gray',
        iconHover: 'profile-gray',
        counter: null,
        hover: false,
      },
      orders: {
        text: 'I miei ordini',
        href: 'https://shop.unicreditsubitocasa.it/area-personale/ordini/',
        icon: 'orders-gray',
        iconHover: 'orders-gray',
        counter: null,
        hover: false,
        external: true,
        tealium: {
          event_name: 'shop_menu_click',
          shop_menu_name: 'I miei ordini',
        },
      },
      logout: {
        text: 'Logout',
        icon: 'logout-gray',
        iconHover: 'logout-gray',
        hover: false,
        logout: true,
      },
    },
    sections: [
      {
        label: 'Casa',
        value: 1,
        id: 1,
        slug: 'venditaimmobili',
      },
      {
        label: 'Nuove costruzioni',
        value: 'all',
        id: 3,
        slug: 'nuovecostruzioni',
      },
      {
        label: 'Commerciale',
        value: 2,
        id: 2,
        slug: 'immobilicommerciali',
      },
      {
        label: 'Prestige',
        value: 'all',
        id: 4,
        slug: 'immobilidipregio',
      },
      ...($config.uclamActive
        ? [
            {
              label: 'Proprietà UniCredit',
              value: 'all',
              id: 6,
              slug: 'proprieta-unicredit',
            },
          ]
        : []),
    ],
    responseErrorsToArray(res) {
      const errors = []
      if (
        !res ||
        !res.data ||
        !res.data.errors ||
        typeof res.data.errors !== 'object'
      )
        return errors

      const resErrors = res.data.errors
      for (const err in resErrors) {
        resErrors[err].forEach((el) => {
          errors.push(el)
        })
      }
      return errors
    },
    tealiumCategory(property) {
      let cat = ''

      // TMP
      if (property.type === 'v2') {
        cat =
          // eslint-disable-next-line eqeqeq
          property.source_id == 4
            ? 'proprieta-unicredit'
            : property.is_luxury
            ? 'immobilidipregio'
            : property.building.category.uri
      } else {
        cat = property.category_uri
      }

      const tealiumCategoryMap = {
        venditaimmobili: 'residenziale',
        nuovecostruzioni: 'progetto',
        immobilicommerciali: 'commerciale',
        immobilidipregio: 'pregio',
        'proprieta-unicredit': 'proprieta-unicredit',
      }

      return tealiumCategoryMap[cat] ?? ''
    },

    async findGeo(stateSlug, citySlug, zoneSlug, zonesIds) {
      const geo = {}

      const statesRes = await $axios.get('/_api/api/v2/geo/states')
      const statesData = statesRes.data.data
      const state = statesData.find((it) => it.code.toLowerCase() === stateSlug)

      if (!state) {
        return {
          geo,
          zones: [],
        }
      }

      geo.provincia = state.id
      geo.provinciaKeyurl = state.slug
      geo.provinciaNome = state.name
      geo.provinciaSigla = state.code
      geo.testo = `${state.name} (Provincia)`

      if (!citySlug) {
        return {
          geo,
          zones: [],
        }
      }

      const stateRes = await $axios.get(`/_api/api/v2/geo/states/${state.id}`, {
        params: { embed: 'cities' },
      })
      const stateData = stateRes.data?.data
      const { cities } = stateData

      const city = cities.find((it) => it.slug === citySlug)

      if (!city) {
        return {
          geo,
          zones: [],
        }
      }

      geo.comune = city.id
      geo.comuneKeyurl = city.slug
      geo.comuneNome = city.name
      geo.testo = `${city.name} (Tutto il comune)`

      if (!zoneSlug && !zonesIds) {
        return {
          geo,
          zones: [],
        }
      }

      const cityRes = await $axios.get(`/_api/api/v2/geo/cities/${city.id}`, {
        params: { embed: 'zones' },
      })
      const cityData = cityRes.data?.data

      let zones = []
      switch (true) {
        case Boolean(zoneSlug):
          zones = cityData.zones.filter((it) => it.slug === zoneSlug)
          break

        case Boolean(zonesIds):
          zones = cityData.zones.filter((it) => zonesIds.includes(it.id))
          break
      }

      return {
        geo,
        zones,
      }
    },

    imgAlias(src) {
      const srcUrl = new URL(src)
      const { hostname, pathname } = srcUrl

      if (pathname.match(/\/news\//)) {
        return `/newsimg${pathname}`
      }

      const host = hostname.split(/\.(.+)/)
      const subdomain = host[0]
      const domain = host[1]

      if (domain !== 'getrix.it') {
        return `/mediaucsc${pathname}`
      }

      if (subdomain === 'mediaserver') {
        return `/ms${pathname}`
      }

      return `/${subdomain + pathname}`
    },

    async verifyUser(id, token, user, options) {
      const res = {
        status: 200,
        message: 'Verified',
        redirect: '',
      }

      // eslint-disable-next-line eqeqeq
      if (user.id != id) {
        res.status = 401
        res.message = 'Operazione non autorizzata.'
        throw res
      }

      try {
        await $axios.get(`/_api/auth/verify-email/${id}/${token}`, options)

        $cookies.set('optin', 'true', {
          domain: $config.cookieDomain,
          secure: $config.cookieSecure,
          path: '/',
        })

        const queryParams = { ...query }
        const queryString = Object.keys(queryParams)
          .filter((key) => key !== 'token')
          .map((key) => key + '=' + queryParams[key])
          .join('&')

        if (user.autogenerated_password) {
          res.redirect =
            '/area-riservata/registrazione-veloce/modifica-password'
        } else if (user.type === 'c' && user.counts.saved_searches) {
          const searches = await $axios.get('/_api/api/v1/user/searches')
          res.redirect = searches.data.data[0].link
        } else {
          res.redirect =
            user.type === 'v' && !user.seller
              ? '/vendo'
              : user.type === 'e'
              ? '/servizi-documenti-casa'
              : '/area-riservata/dashboard'
        }

        if (queryString) res.redirect += `?${queryString}`

        return res
      } catch (e) {
        res.status = 401
        res.message = 'Questa pagina di conferma non è valida.'
        throw res
      }
    },
    addFavoriteToStore(property, isNotify) {
      // TODO: when listing is v2, remove all the checks and the TMP comments
      const type =
        property.type === 'v2' // TMP
          ? property.building.property_type.parent
          : property.property_type
          ? property.property_type
          : 'immobile'

      const category = this.tealiumCategory(property)

      // eslint-disable-next-line camelcase
      const virtual_tour =
        (property.type === 'v2' && property.features.virtual_tour) || // TMP
        property.virtual_tour
          ? '1'
          : '0'

      const showcase =
        (property.type === 'v2' && property.features.is_showcase) || // TMP
        property.showcase
          ? '1'
          : '0'

      const network =
        (property.agent && property.agent.type === 'network_agenti') || // TMP
        (property.partner && property.partner.type === 'network_agenti')
          ? '1'
          : '0'

      store.commit('interactions/setFavorite', {
        time: Date.now(),
        property: {
          id: property.id,
          type,
          category,
          virtual_tour,
          showcase,
          certificate: property.certificate ? '1' : '0',
          network,
          notify: isNotify,
          // eslint-disable-next-line eqeqeq
          uclam: property.source_id == 4 ? '1' : '0',
        },
      })
    },
    privateGardenValue(garden, surface, unit, absentStr, presentStr) {
      if (!garden) return absentStr

      return `${
        surface
          ? this.formatNumber(surface) + ' ' + unitsKeys[unit]
          : garden === 'present'
          ? presentStr
          : privateGardenKeys[garden]
      }`
    },
    residentialTotalRooms(extraFeatures) {
      const totalOthers =
        parseInt(extraFeatures.living_rooms) +
        parseInt(extraFeatures.other_rooms)

      const total = totalOthers + parseInt(extraFeatures.bedrooms)
      const totalRooms = `${total < 6 ? total : '5+'} (${
        extraFeatures.bedrooms
      } camere da letto${totalOthers ? ', ' + totalOthers + ' altri' : ''}), ${
        parseInt(extraFeatures.bathrooms) < 4 ? extraFeatures.bathrooms : '3+'
      } bagni${
        extraFeatures.kitchen ? ', ' + kitchenKeys[extraFeatures.kitchen] : ''
      }`
      return totalRooms
    },
    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    },
    formatNumber(value) {
      return (Number.isInteger(value) ? value : 0).toLocaleString('it-IT')
    },
    roundNumber(value, digits) {
      const num = typeof value === 'number' ? value : 0
      return +(Math.round(num + `e+${digits}`) + `e-${digits}`)
    },
    getRelativeUrl(src) {
      return src ? new URL(src).pathname : null
    },
    formatDate(date) {
      const d = new Date(date)
      return `${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()}`

      //   const options = {
      //     year: 'numeric',
      //     month: 'long',
      //     day: 'numeric',
      //   }
      //   return new Date(date).toLocaleDateString('it-IT', options)
    },
    mapPost(post) {
      return {
        featuredImage: post.featuredImage
          ? {
              src: this.imgAlias(post.featuredImage.node.mediaItemUrl),
              alt: post.featuredImage.node.altText,
              title: post.featuredImage.node.title,
            }
          : null,
        title: post.title,
        date: post.date,
        modified: post.modified,
        excerpt: post.excerpt,
        link: `/news${post.uri}`,
        category: {
          name: post.categories?.nodes[0]?.name,
        },
      }
    },
    handleModalFocus(
      e,
      firstFocusableElement,
      lastFocusableElement,
      container
    ) {
      if (e.key !== 'Tab') return

      if (!firstFocusableElement) return

      if (!container.contains(document.activeElement)) {
        firstFocusableElement.focus()
        e.preventDefault()
        return
      }

      if (e.shiftKey && document.activeElement === firstFocusableElement) {
        lastFocusableElement.focus()
        e.preventDefault()
      } else if (
        !e.shiftKey &&
        document.activeElement === lastFocusableElement
      ) {
        firstFocusableElement.focus()
        e.preventDefault()
      }
    },
  })
}
